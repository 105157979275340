
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("website/app", function(){ return i("website/app.ts");});
d("website/config/environment", function(){ return i("website/config/environment.js");});
d("website/router", function(){ return i("website/router.ts");});
d("website/services/page-title", function(){ return i("website/services/page-title.js");});
d("website/instance-initializers/clear-double-boot", function(){ return i("website/instance-initializers/clear-double-boot.js");});
d("website/locations/none", function(){ return i("website/locations/none.js");});
d("website/services/fastboot", function(){ return i("website/services/fastboot.js");});
d("website/initializers/app-version", function(){ return i("website/initializers/app-version.js");});
d("website/templates/index", function(){ return i("website/templates/index.ts");});
d("website/controllers/index", function(){ return i("website/controllers/index.ts");});
d("website/templates/application", function(){ return i("website/templates/application.ts");});
d("website/routes/application", function(){ return i("website/routes/application.ts");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("website/initializers/ajax", function(){ return i("website/initializers/ajax.js");});
d("website/initializers/error-handler", function(){ return i("website/initializers/error-handler.js");});
  }





if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('website/app')['default'].create({"name":"website","version":"0.0.0+318ede96"});
  }
}


